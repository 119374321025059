@import "../../global.scss";

.intro {
   background-color: white;
   display: flex;

   @include mobile {
      flex-direction: column;
      align-items: center;
   }

   .left {
      flex: 0.5;
      overflow: hidden;

      .imagecontainer {
         width: 700px;
         height: 700px;
         border-radius: 50%;
         background-color: crimson;
         display: flex;
         align-items: flex-end;
         justify-content: center;
         float: right;

         @include mobile {
            align-items: flex-start;
         }

         img {
            height: 90%;

            @include mobile {
               height: 50%;
            }
         }
      }
   }

   .right {
      flex: 0.5;
      position: relative;
      width: 100%;

      .wrapper {
         width: 100%;
         height: 100%;
         padding-left: 50px;
         display: flex;
         flex-direction: column;
         justify-content: center;

         @include mobile {
            padding-left: 0;
            align-items: center;
         }

         h1 {
            font-size: 60px;
            margin: 10px 0;

            @include mobile {
               font-size: 40px;
            }
         }
         h2 {
            font-size: 35px;
         }
         h3 {
            font-size: 30px;
            height: 50px;

            @include mobile {
               font-size: 20px;
            }

            span {
               font-size: 30px;
               color: crimson;
            }

            .ityped-cursor {
               animation: blink 1s infinite;
            }

            @keyframes blink {
               50% {
                  opacity: 1;
               }

               100% {
                  opacity: 0;
               }
            }
         }
      }

      .clickBottom {
         position: absolute;
         bottom: 10px;
         left: 40%;

         img {
            width: 30px;
            animation: arrowBlink 2s infinite;
         }

         @keyframes arrowBlink {
            100% {
               opacity: 0;
            }
         }
      }
   }
}

a {
   text-decoration: none;
   color: inherit;
}

.button {
   float: left;
   width: 60px;
   height: 60px;
   cursor: pointer;
   background: #fff;
   overflow: hidden;
   border-radius: 50px;
   transition: all 0.3s ease-in-out;
   box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.button span {
   font-size: 20px;
   font-weight: 500;
   line-height: 60px;
   margin-left: 10px;
}

// .button:hover {
//    width: 200px;
// }

.social {
   margin-top: 50px;
}
.button:nth-child(1):hover .icon {
   background: #333;
}

.button:nth-child(2):hover .icon {
   background: #0e76a8;
}

.button:nth-child(3):hover .icon {
   background: #1da1f2;
}

.button .icon {
   width: 60px;
   height: 60px;
   text-align: center;
   border-radius: 50px;
   transition: all 0.3s ease-in-out;
}

.iconLink {
}

.button .icon .i {
   font-size: 25px;
   line-height: 60px;
   margin-top: 20px;
   // transition: all 0.3s ease-in-out;
}

.button:hover .i {
   color: #fff;
}
